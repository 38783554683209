import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { CurreLocalStorage } from "../../util/localStorage";
const initialState = {
  currencies: [
    {
      id: "1YiC1Sz9MkYPY3LG8MlN",
      name: "USD",
      nameAr: "USD",
      toUSD: 1,
      toAED: 3.636376859552217,
      toSAR: 3.75,
      toEUR: 0.900901,
      toBHD: 0.376938,
      toKWD: 0.305515,
      toOMR: 0.384969,
      toQAR: 3.64573,
    },
    {
      id: "papAwH0XoKC3AmT9YmR7",
      name: "AED",
      nameAr: "AED",
      toUSD: 0.27,
      toAED: 1,
      toSAR: 1.0700108071091519,
      toEUR: 0.24631,
      toBHD: 0.102625,
      toKWD: 0.08318,
      toOMR: 0.104812,
      toQAR: 0.99259,
    },
    {
      id: "vIv6NVJ1rA6jwrsfoiXT",
      name: "SAR",
      nameAr: "SAR",
      toUSD: 0.26666666666666666,
      toAED: 0.93457,
      toSAR: 1,
      toEUR: 0.24155,
      toBHD: 0.100431,
      toKWD: 0.081401,
      toOMR: 0.102571,
      toQAR: 0.97136,
    },
    {
      id: "EUR6NVJ1rA6jwrsfoiXT",
      name: "EUR",
      nameAr: "EUR",
      toUSD: 1.09,
      toAED: 4.059924485404571,
      toSAR: 4.1,
      toEUR: 1,
      toBHD: 0.100431,
      toKWD: 0.081401,
      toOMR: 0.102571,
      toQAR: 0.97136,
    },
    {
      id: "KWD",
      name: "KWD",
      nameAr: "KWD",
      toUSD: 3.27316,
      toAED: 12.022,
      toSAR: 12.2849,
      toEUR: 2.96469,
      toBHD: 1.23378,
      toKWD: 1,
      toOMR: 1.26007,
      toQAR: 11.9331,
    },
    {
      id: "QAR",
      name: "QAR",
      nameAr: "QAR",
      toUSD: 0.27429,
      toAED: 1.00747,
      toSAR: 1.02948,
      toEUR: 0.24844,
      toBHD: 0.103392,
      toKWD: 0.083801,
      toOMR: 0.105594,
      toQAR: 1,
    },
    {
      id: "BHD",
      name: "BHD",
      nameAr: "BHD",
      toUSD: 2.65296,
      toAED: 9.74418,
      toSAR: 9.9571,
      toEUR: 2.40294,
      toBHD: 1,
      toKWD: 0.810518,
      toOMR: 1.02131,
      toQAR: 9.67196,
    },
    {
      id: "OMR",
      name: "OMR",
      nameAr: "OMR",
      toUSD: 2.59761,
      toAED: 9.5409,
      toSAR: 9.74938,
      toEUR: 2.35281,
      toBHD: 0.979139,
      toKWD: 0.793609,
      toOMR: 1,
      toQAR: 9.47019,
    },
  ],
  selectedcurrency: "",
  currencydefault: "papAwH0XoKC3AmT9YmR7",
  countryCode: "AE",
};

const CurrencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    GetCurrency: (state, { payload }) => {
      state.currencies = payload;
    },
    SelectedCurrencies: (state, { payload }) => {
      state.selectedcurrency = payload;
      CurreLocalStorage(payload);
    },
    GetCurrencyDefault: (state, { payload }) => {
      state.currencydefault = payload;
    },

    GetCountryCode: (state, { payload }) => {
      state.countryCode = payload;
    },
  },
});

export const {
  GetCurrency,
  SelectedCurrencies,
  GetCurrencyDefault,
  SetFlags,
  GetCountryCode,
} = CurrencySlice.actions;

export default CurrencySlice.reducer;
